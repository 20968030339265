import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from '@heroicons/react/20/solid'
import {
  ColumnDef,
  PaginationState,
  SortingState,
  TableOptions,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import React, { useState } from 'react'

import { Button } from '../Button'
import Spinner from '../Spinner'

interface DataTableProps<T extends { id: string }> {
  data: T[]
  columns: ColumnDef<T, keyof T>[]
  title?: string
  description?: string
  isLoading?: boolean
  onCreate?: () => void
  itemsPerPage?: number
  tableOptions?: Partial<TableOptions<T>>
}

export default function DataTable<T extends { id: string }>({
  data,
  columns,
  title,
  description,
  isLoading = false,
  onCreate,
  itemsPerPage = 10,
  tableOptions,
}: DataTableProps<T>) {
  const [sorting, setSorting] = useState<SortingState>([])
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: itemsPerPage,
  })

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    ...tableOptions,
  })

  return (
    <div className="w-full bg-surface rounded-lg shadow-lg overflow-hidden">
      {/* Header */}
      <div className="flex justify-between p-4 border-b border-accent">
        <div className="sm:flex-auto">
          {title && (
            <h1 className="text-on-surface text-xl font-semibold">{title}</h1>
          )}
          {description && (
            <p className="text-on-surface/75 text-sm">{description}</p>
          )}
        </div>
        {onCreate && (
          <div className="mb-2 mt-4 self-end sm:ml-16 sm:mt-0">
            <Button onClick={onCreate}>Create</Button>
          </div>
        )}
      </div>

      {/* Table */}
      <div className="overflow-x-auto">
        <table className="table-auto w-full">
          {/* Header */}
          <thead className="bg-surface-1">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={`px-4 py-3 text-left text-sm font-medium text-on-surface w-full whitespace-nowrap ${header.column.getCanSort() && 'hover:bg-surface-2'} transition-colors`}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        className={`flex items-center ${
                          header.column.getCanSort() &&
                          'cursor-pointer select-none'
                        }`}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        {{
                          asc: (
                            <ChevronUpIcon
                              className="ml-2 h-5 w-5"
                              aria-hidden="true"
                            />
                          ),
                          desc: (
                            <ChevronDownIcon
                              className="ml-2 h-5 w-5"
                              aria-hidden="true"
                            />
                          ),
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          {/* Body */}
          <tbody className={`divide-y divide-accent`}>
            {isLoading ? (
              <tr>
                <td
                  colSpan={columns.length}
                  className="px-4 py-4 text-center text-sm text-on-surface"
                >
                  <div className="flex justify-center">
                    <Spinner size="lg" />
                  </div>
                </td>
              </tr>
            ) : table.getRowModel().rows.length > 0 ? (
              table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className={`px-4 py-3 ${cell.column.getIsFirstColumn() ? 'text-on-surface' : 'text-on-surface/75'}`}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  ))}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={columns.length}
                  className="px-4 py-4 text-center text-sm text-on-surface"
                >
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      {data.length > 0 && (
        <div className="bg-surface-1 px-4 py-3 flex items-center justify-between border-t border-accent ">
          <div>
            <p className="text-sm text-on-surface/50">
              Showing{' '}
              {table.getState().pagination.pageIndex *
                table.getState().pagination.pageSize +
                1}{' '}
              to{' '}
              {Math.min(
                (table.getState().pagination.pageIndex + 1) *
                  table.getState().pagination.pageSize,
                data.length,
              )}{' '}
              of {data.length} results
            </p>
          </div>
          <div className="flex items-center space-x-2">
            <Button
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              size="sm"
              aria-label="Previous"
              name="previous"
              variant={'secondary'}
            >
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </Button>
            <span className="text-sm text-on-surface">
              Page {table.getState().pagination.pageIndex + 1} of{' '}
              {table.getPageCount()}
            </span>
            <Button
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              size="sm"
              aria-label="Next"
              name="next"
              variant={'secondary'}
            >
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}
