import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

import Spinner from '../components/Spinner'

interface AppConfig {
  ENV: string
  COGNITO_CONFIG: {
    UserPoolId: string
    ClientId: string
  }
}

const ConfigContext = createContext<AppConfig | null>(null)

export const useConfig = () => {
  const context = useContext(ConfigContext)
  if (context === null) {
    throw new Error('useConfig must be used within a ConfigProvider')
  }
  return context
}

interface ConfigProviderProps {
  children: ReactNode
}

const fetchConfig = async (): Promise<AppConfig> => {
  const response = await fetch('/config.json')
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`)
  }
  return await response.json()
}

export const ConfigProvider: React.FC<ConfigProviderProps> = ({ children }) => {
  const [config, setConfig] = useState<AppConfig | null>(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const loadConfig = async () => {
      try {
        const loadedConfig = await fetchConfig()
        setConfig(loadedConfig)
      } catch (error) {
        console.error('Failed to load configuration:', error)
      } finally {
        setLoading(false)
      }
    }
    loadConfig()
  }, [])

  if (loading) {
    return (
      <div>
        <Spinner size="page" className="text-primary" />
      </div>
    )
  }

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  )
}
