import { EyeIcon } from '@heroicons/react/20/solid'
import { ColumnDef } from '@tanstack/react-table'

import { Project } from '@droidmap/project-service-contract'

import { Button } from '../../components/Button'

type ProjectActions = {
  onDelete: (id: string) => void
  onView: (id: string) => void
}

export const columns: ColumnDef<Project, keyof Project>[] = [
  {
    header: 'Name',
    cell: ({ row, table }) => {
      const { onView } = table.options.meta as ProjectActions
      const { id, name } = row.original
      return (
        <span className="cursor-pointer" onClick={() => onView(id)}>
          {name}
        </span>
      )
    },
  },
  {
    accessorKey: 'created',
    header: 'Created At',
    cell: (info) => new Date(info.getValue()).toLocaleDateString(),
  },
  {
    id: 'actions',
    header: 'Actions',
    cell: ({ row, table }) => {
      const { onView } = table.options.meta as ProjectActions
      return (
        <div className="flex space-x-2">
          <Button onClick={() => onView(row.original.id)} size="icon">
            <EyeIcon className="w-5 h-5" />
          </Button>
          {/* <Button
            onClick={() => onDelete(row.original.id)}
            size="icon"
            variant="destructive"
          >
            <TrashIcon className="w-5 h-5" />
          </Button> */}
        </div>
      )
    },
  },
]
